*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
body{
    box-sizing: border-box;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $main-font;
}
.container{
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 10px;
}
.img-responsive{
    width: 100%;
    height: auto;
    display: block;
    // image-rendering: pixelated;
}
.imageContainer{
    margin-bottom: 20px;
}
.elStandard{
    margin: 1rem 0;
}
@media only screen and (min-width: $breakpoint-small) {
    .elStandard{
        margin: 3rem 0;
    }
}


.bg-white{
    background-color: $white;
}
.bg-white-transparent{
    background-color: rgba($white,0.8);
}

.full-height {
    height: 100%;
}
.vertical-center {
    display: flex;
    align-items: center;
}
@media only screen and (max-width: $breakpoint-med) {
    .order-1{
        order: 1;
    }
    .order-2{
        order: 2;
    }
}


.mtop-1 {
    margin-top: 1rem;
}


.ie9 img[src$=".svg"] {
    width: 100%; 
}
/* 2 */
// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//     img[src$=".svg"] {
//     width: 100%; 
//     }
// }
