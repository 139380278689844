.programm-container {
  background-color: $primary;
  padding: 1.5rem;

  > h1,
  > p {
    text-align: center;
    color: $white;
  }
  h1 {
    margin-top: 0;
    text-transform: uppercase;
  }

  table {
    margin-top: 2rem;
    padding: 1em 0;
    width: 100%;
    min-width: 60em;
    background-color: $white;
    border-collapse: collapse;
    color: $black;

    tr {
      &.tr-mar-top > td {
        padding-top: 1rem;
      }
      &.tr-mar-bot > td {
        padding-bottom: 1rem;
      }

      td {
        padding: 0.1em 1em;
        vertical-align: top;
        line-height: 1.5;
      }
    }

    .tr--separator {
      background-color: $primary;
      height: 1rem;
    }
  }
}
@media only screen and (min-width: $breakpoint-small) { 
    .programm-container {
        padding: 3rem;
    }
}