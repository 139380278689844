
footer {
    margin-bottom: 5rem;
  
    .metanav {
      margin: 2em 0;
  
      a {
        text-decoration: none;
        margin: 0 1em 0 0;
        
        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }