.heading-sticker {
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;

  > h1 {
    display: inline-block;
    padding: 0.5em 1em;
    margin-bottom: 5rem;
    transform: rotate(-3deg);
    background-color: $primary;

    color: $white;
    line-height: 1.3em;
  }
}

@media only screen and (min-width: $breakpoint-small) {
  .heading-sticker {
    // margin-left: 0rem;
    // margin-left: 0rem;
  }
}

#info {
  margin-bottom: 2rem;
}
@media only screen and (min-width: $breakpoint-med) { 
    #info {
        // margin-bottom: 8rem;
    }
}

.infobubbles {
  .bubble {
    &__circle {
      display: inline-block;
      position: relative;
      z-index: -10;
      padding: 7rem;
      border-radius: 100%;
      background-color: $secondary;
    }

    &__content {
      margin-left: 2rem;
      margin-top: -11rem;
      margin-bottom: 5rem;
      overflow: visible;

      h1 {
        margin-bottom: 0.15em;
      }
      p {
        margin-left: 3rem;
      }
    }
    &:last-child .bubble__content {
      margin-bottom: 5rem;
    }

    &--right {
      text-align: right;
      .bubble__content {
        margin-left: 0;
        margin-right: 3rem;
        p {
          margin-left: 0;
          margin-right: 3rem;
        }
      }
    }
  }
}
@media only screen and (min-width: $breakpoint-small) {
  .infobubbles {
    .bubble {
        &__circle {
            padding: 10rem;
        }
        &__content {
            margin-left: 3rem;
            margin-top: -14rem;
            margin-bottom: 0;
        }
        &:last-child .bubble__content {
            margin-bottom: 7rem;
          }
    }
  }
}

.mitmachen {
  max-width: 40rem;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
@media only screen and (min-width: $breakpoint-small) { 
    margin-top: 5rem;
    margin-bottom: 10rem;
}