body {
  font-family: $main-font;
}
a {
  color: $text-color;
  @include transition;
}
h1, h2, h3 {
  font-family: $main-font;
  font-weight: bolder;
  color: $primary;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  margin-bottom: 0.5em;
}

$heading-scale-factor: 0.7;
h1 { @include fontSize(calc(40px * #{$heading-scale-factor})); }
h2 { @include fontSize(calc(30px * #{$heading-scale-factor})); }
h3 { @include fontSize(calc(20px * #{$heading-scale-factor})); }

@media only screen and (min-width: $breakpoint-small) {

  h1 { @include fontSize(40px); }
  h2 { @include fontSize(30px); }
  h3 { @include fontSize(20px); }
}

.larger {
  font-size: 1.3em;
}
.smaller {
  font-size: 0.85em;
}

.headlineFooter {
  @include fontSize(18px);
  color: $white;
  font-family: $main-font;
  margin-bottom: 20px;
}

.elContainerBackground_varRed {

  .sectionHeadline.middle {
    color: $white;
    border-color: $white;

    &:before {
      border-color: $white;
    }

    span {
      background-color: $primary;
    }
  }
}

.sectionHeadlineSmall {
  font-family: $main-font;
  @include fontSize(20px);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  margin-bottom: 5px;
}

.sectionHeadlineBig {
  font-family: $main-font;
  @include fontSize(50px);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -2px;
  margin-bottom: 20px;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-white {
  color: $white;
}

p {
  line-height: 1.5;
}
.pragraphColumn {
  max-width: 70%;
  margin: 0 auto;
  @include responsive(sm) {
    column-count: 1;
    max-width: 100%;
  }
}

.longtext p{
  margin-bottom: 1em;
  &:last-child { margin-bottom: inherit; }
}

strong {
  font-weight: bolder;
  color: $primary;
}

a.moreLink {
  display: inline-block;
  text-decoration: none;
  position: relative;
  transform: translate(30px, 0px);
  @include responsive(sm) {
    transform: translate(0);
  }
  :after {
    padding-left: 10px;
    content: "\f054";
    font-family: "FontAwesome";
  }
}

