/*** defines ***/
$height-span: 3px;
$width-span: 28px;

$height-gradient: 100vh;

// $main-color: $primary;
// $main-color-inverse: #3B305F;
// $trasparent-color: rgba(255,255,255,.1);
// $inverse-color: rgba(59, 48, 95,1);

$def-color: #fff;
$int-color: #777;
$act-color: #444;

/*** menu full page ***/
.hide {
  display: none;
}


// TODO Animation aus Element heraus, nicht aus Ecke
.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: hidden;
  // z-index: 9;
  overflow: hidden;
  z-index: 3;
  transition: opacity 0.5s, visibility 0.35s, width 0.35s;

  &:after {

    content: "";
    background: $primary;
    right: calc(15px + 25px - 20px);
    top: calc(15px + 25px - 20px);
    width: 40px;
    height: 40px;
    position: fixed;
    transition: all 0.35s ease-in-out;
  }

  &.open {
    opacity: 1;
    visibility: visible;

    &:after {
      transform: scale(50, 50);

      // fallback for big screens
      width: 110vw;
      height: 110vh;
      right: 0;
      top: 0;
    }
    li {
      animation: fadeInRight 0.25s ease forwards;
      animation-delay: 0.25s;
    }
  }


  .menu-list {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
    z-index: 100;

    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;

      li {
        display: block;
        height: 20%;
        height: calc(100% / 5);
        min-height: 50px;
        position: relative;
        opacity: 0;

        a {
          display: block;
          position: relative;
          color: $def-color;
          text-decoration: none;
          overflow: hidden;
          padding: 8px 15px;
          transition: all 0.5s;

          &:hover {
            transform: translate(0, -5px);
          }
        }
      }
    }
  }
}

.burger {
 
  width: $width-span;
  height: $height-span * 5;
  top: 15px;
  right: 15px;
  position: fixed;
  padding: 25px;
  cursor: pointer;
  background-color: $primary;
  // border-radius: 50%;
  z-index: 10;

  & span {
    display: block;
    position: fixed;
    top: 28px;
    right: 26px;
    width: $width-span;
    height: $height-span;
    background-color: $white;
    transition: all 0.35s;

    z-index: 50;

    &:nth-of-type(2) {
      top: 39px;
    }

    &:nth-of-type(3) {
      top: 50px;
    }
  }

  &.active {
    // background-color: $white;
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      // background: $black;
    }

    .middle {
      opacity: 0;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      // background: $black;
    }
  }
}

@media (max-width: 768px) {
  .menu-list {
    font-size: 30px;
    li {
      height: auto !important;
      a {
        padding: 15px 10px !important;
        font-size: 30px;
      }
    }
  }

  .div-gradient {
    & .img-gradient {
      background-position: 50% 0px !important;
    }

    & .gradient {
    }
  }
}
@media (max-width: 350px) {
  .menu-list a {
    font-size: 20px !important;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.sticky-btn {
  position: fixed;
  right: 70px;
  z-index: 3;
  top: 15px;
  transition: all 1s;
  @include responsive(sm) {
    display: none;
  }
}
.hide {
  opacity: 0;
  transition: all 1s;
}
.show {
  opacity: 1;
  transition: all 1s;
}
