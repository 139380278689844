@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin responsive($breakpoint) {
    @if $breakpoint == xs {
        @media only screen and (max-width: 540px) { @content };    //600px
    }
    @if $breakpoint == sm {
        @media only screen and (max-width: 768px) { @content };    //600px
    }
    @if $breakpoint == md {
        @media only screen and (max-width: 1024px) { @content };     //900px
    }
    @if $breakpoint == lg {
        @media only screen and (max-width: 1200px) { @content };    //1200px
    }
    @if $breakpoint == lgxl {
        @media only screen and (min-width: 1024px) and (max-width:1100px) { @content };    //1200px
    }
   
}

@mixin column($width){
    float: left;
    width: $width;
    
}
@mixin layoutContainer($width){
    max-width: $width;
    margin: 0 auto;
    @include responsive(sm){
        max-width: 100%;
    }
}


//Transitions
@mixin transition(){
    transition: .3s ease-in-out all;
}