

.GallerySection {
  margin-top: 5rem;
}
  
  .popup-gallery a{
    &:nth-child(4n+1){
      clear: both;
    }
  }
  .thumb{
    width: 25%;
    float: left;
  
    @include responsive(xs){
      width: 100%;
      float: none;
    }
    
    img{
      border:10px solid white;
    }
  }
  