
.ideenbox {
    margin-top: 0rem;
    margin-bottom: 2rem;
    text-align: center;
  
    &--pfeil {
      position: relative;
      right: 18%;
      width: 38%;
  
      animation: arrow ease-in-out 2s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
    }
    &--box {
      width: 50%;
    }
    &--ende {
      margin-top: 6rem;
      margin-bottom: 3rem;
    }
  }
  @media only screen and (min-width: $breakpoint-small) { 
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: $breakpoint-small) {
    .ideenbox {
      &--pfeil {
        right: 11%;
        width: 22%;
      }
      &--box {
        width: 28%;
      }
    }
  }
  
  @keyframes arrow{
    0% {
      transform: translate(0px,0px) rotate(0deg) ;
    }
    50% {
      transform: translate(6%,6%)   rotate(5deg) ;
    }
    100% {
      transform: translate(0px,0px) rotate(0deg) ;
    }
  }


  .formresponse, .formresponse--inline {
    text-align: center;
    color: $black;
    background-color: $grey;

    h3 {
      color: inherit;
    }

    ul > li {
      list-style-type: square;
      line-height: 1.5;
      margin-left: 1.5em;
    }

    &.success {
      background-color: #A5F08F;
    }
    &.error {
      background-color: #FF7B67;
    }
  }
  .formresponse {
    padding: 3rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .formresponse--inline {
    box-shadow: inset 0 10px 10px -10px rgba(0,0,0,0.5);
    font-size: 0.8em;
    width: 100%;
    padding: 0.5em;
  }
  
  .ideenformular {
    border: 2px solid $black;
    padding:1rem 1rem;
  
    &.container {
      padding-bottom: 1rem;
    }
  
    input[type="text"],
    input[type="email"],
    textarea {
      padding: 0.5em;
      width: 100%;
      border: none;
      background-color: $grey;
    }
    // input[type="text"],
    // input[type="email"] {
    //   &:invalid:not(:placeholder-shown) { border-left: 5px solid maroon; }
    //   &:valid:not(:placeholder-shown) { border-left: 5px solid darkgreen; }
    // }
  
    textarea {
      min-height: 10em;
    }
  }
  @media only screen and (min-width: $breakpoint-small) { 
    .ideenformular { 
      padding: 3rem 2rem;
    }
  }

