// TODO debug entfernen
#VORSCHAU{
    display: none;
    position: absolute;
    z-index: 9999;
    opacity: 0.2;
}

.elParallax{
    overflow: hidden;
    position: relative;
}

.parralax-container{
    width: 100%;
    text-align: center;
    margin-bottom: -5px;
    @include responsive(xs){
        width: 180%;
        margin-left: -40%;
    }

    #scene{
        overflow: hidden;
        .layer{
            display: block;
            height: 100%;
            width: 100%;
            padding: 0;
            margin: 0;

            [class^="layer-"] {
            
                position: absolute;

                > img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .backgroundContainer{
            width: 100%;
            height: auto;
        }

        
        
        .layer-1{
            // display: none;
            width: 36.7%;
            top: 32.5%;
            left: 43%;

        }
        .layer-2{
            // display: none;
            width: 62.6%;
            top: 29.3%;
            left: 15%;
        }
        .layer-3{
            // display: none;
            width: 23.7%;
            top: 48.4%;
            left: 43.4%;
        }
        .layer-4{
            // display: none;
            width: 63.8%;
            top: 52.6%;
            left: 33%;
        }
        .layer-5{
            // display: none;
            width: 55.9%;
            top: 61.9%;
            left: 11.8%;
        }
        .layer-6{
            // display: none;
            width: 29.6%;
            top: 73.6%;
            left: 23.2%;
        }
        .bird{
            position: absolute;
            img{
                animation: bird 2s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
        }
        .bird-1{
            top: 22%;
            left: 56%;
            img { width: 50px; }
        }
        .bird-2{
            top: 18%;
            left: 60%;
            img { 
                width: 32px; 
                animation-delay: -0.5s;
                animation-duration: 2.3s;
            }
        }
        .bird-3{
            top: 30%;
            left: 63%;
            img { 
                width: 14px; 
                animation-delay: -1s;
                animation-duration: 1.5s;
            }
        }
    }
}

.logo{
    position: absolute;
    width: 21%;
    top: 3%;
    left: 28%;

    img {
        width: 100%;
        height: auto;
    }
}
.scrollme{
    position: absolute;
    width: 60px;
    top:60%;
    right: 10%;

    animation: wiggle 5s ease-in-out 2s infinite normal;
}


@keyframes bird {
    0% {
    
        transform: translate3d(2%,0,0) rotateZ(-20deg); 
    }
    100% {

        transform: translate3d(50%,0,0) rotateZ(20deg); 
    } 
}

@keyframes wiggle {
    5% {
        transform: translate(0,-9px) rotate(8deg);
    }
    10% {
        transform: translate(0,+7px) rotate(-16deg);
    }
    15% {
        transform: translate(0,-2px) rotate(4deg);
    }
    20% {
        transform: translate(0,+4px) rotate(-2deg);
    }
    25% {
        transform: rotate(0deg);
    }
}