.btn{
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    
    font-weight: bold;
    font-size: $btn-size;
    border: none;
    outline: none;
    border-radius: 30px;
    text-align: center;
    margin: 10px;

    &:focus {
        outline: medium invert color;
        outline: initial;
    }
}
.btn-primary{
    background-color: $primary;
    border-radius: 30px;
    color: #FFF;
    min-width: calcRem(150px);
    text-align: center;

    &:hover{
        background-color: lighten($primary,10%)
    }
}
.btn-secondary{
    background-color: $secondary;
    border-radius: 30px;
    color: #FFF;
    min-width: calcRem(150px);
    text-align: center;
    &:hover{
        background-color: darken($secondary,10%)
    }
}
.btn-white{
    background-color: $white;
    color: $black;
    min-width: calcRem(150px);
    &:hover{
        background-color: darken($white,10%)
    }
    
}
.btn-transparent{
    background-color: transparent;
    color: $black;
    min-width: calcRem(150px);
    
    border:1px solid $black;
    &:hover{
        background: $black;
        color: $white;
    }
    
}
