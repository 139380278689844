#ergebnis {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h3 {
        margin-top: 1rem;
    }
}

.presentation {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 45%;

    @media only screen and (min-width: $breakpoint-small) { 
        width: 25%;
    }
}
.presentation-button {
    margin-bottom: 2rem;
}